import * as React from 'react';
import { Key, useEffect, useState } from 'react';
import { ModalForm, ProList } from '@ant-design/pro-components';
import { Button, Input, Tooltip, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import { channelCategoriesAtom, channelsAtom } from './atoms';
import axiosApiInstance from '../../../../api/axiosClient';

type Props = {
    channel: API.Benchmarks.YouTubeBenchmarksHarvestChannel;
};

export const CategorySelectModal = (props: Props) => {
    const [channelCategories, refreshChannelCategories] = useAtom(channelCategoriesAtom);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [channels, refreshChannels] = useAtom(channelsAtom);

    const [search, setSearch] = useState('');

    useEffect(() => {
        setSelectedRowKeys(props.channel.categories.map((category) => category.id));
    }, []);

    const { Search } = Input;

    return (
        <ModalForm
            trigger={
                <Tooltip title='Edit Categories'>
                    <Button type='ghost' icon={<EditOutlined />} />
                </Tooltip>
            }
            width={600}
            style={{ maxHeight: '60vh' }}
            // title={'Select Categories'}
            title={
                <>
                    Select Categories{' '}
                    <Typography.Text type='secondary'>{selectedRowKeys.length} selected</Typography.Text>
                </>
            }
            onFinish={async (values) => {
                return axiosApiInstance
                    .put(
                        `/api/benchmarks/youtube/channel/${props.channel.id}/categories`,
                        selectedRowKeys.map((key) => ({ id: key }))
                    )
                    .then((res) => {
                        refreshChannels();
                        return true;
                    })
                    .catch((err) => false);
            }}
        >
            <Search
                placeholder='Search industry by name'
                onSearch={(value) => setSearch(value.toLowerCase())}
                onChange={(e) => {
                    if (e.currentTarget.value === '') {
                        setSearch('');
                    }
                }}
                allowClear
            />
            <ProList<API.Benchmarks.YouTubeBenchmarksChannelCategory>
                toolBarRender={false}
                options={false}
                tableAlertRender={false}
                metas={{
                    title: {
                        dataIndex: 'category'
                    },
                    description: {},
                    subTitle: {},
                    actions: {},
                    avatar: { render: () => null }
                }}
                rowKey='id'
                headerTitle='Select Categories'
                rowSelection={{
                    // onChange: (keys, selectedRows) => {
                    //     console.log(keys, selectedRows);
                    //     setSelectedRowKeys(keys);
                    // },
                    onSelect: (record, selected) => {
                        // console.log(record, selected)
                        if (selected) {
                            setSelectedRowKeys((prev) => [...prev, record.id]);
                        } else {
                            setSelectedRowKeys((prev) => prev.filter((k) => k !== record.id));
                        }
                    },
                    selectedRowKeys: selectedRowKeys
                }}
                dataSource={channelCategories.filter((c) => c.category.toLowerCase().includes(search))}
                style={{ maxHeight: 'inherit', overflow: 'auto' }}
            />
        </ModalForm>
    );
};

export default CategorySelectModal;
