import {
    Alert,
    Button,
    ConfigProvider,
    Empty,
    Form,
    FormInstance,
    Modal,
    notification,
    Result,
    Row,
    Spin,
    Typography
} from 'antd';
import React, {useEffect, useState} from 'react';
import {authorize, initClient} from '../../../common/gapiHandler';
import {camelCaseSplit, capitalize} from '../../../common/utils';
import './index.css';
import moment from "moment";
import {postRequest} from "../../../api/postRequest";
import {
    CustomRange,
    HalfYear,
    Month,
    NonVerbose,
    Quarter,
    ReportTypeChannel,
    ReportTypeStaticChannel,
    Verbose,
    Year
} from "../../../REPORTING/pages/SmartReports";
import enUSIntl from "antd/lib/locale/en_US";
import ProForm, {
    ProFormDatePicker,
    ProFormDateRangePicker,
    ProFormDependency,
    ProFormGroup,
    ProFormList,
    ProFormRadio,
    ProFormSelect,
    ProFormSwitch
} from "@ant-design/pro-form";
import {FileExcelOutlined, FilePptOutlined} from "@ant-design/icons";
import {PageContainer} from "@ant-design/pro-components";
import dayjs from "dayjs";
import {useAtom} from "jotai";
import {managedReportsParamsAtom} from "../SmartReports/atoms";

export interface IBrandAccountReportingProps {
    //Here we pass the Props Interface
    ppkValue?: string;
    cmsExtraId?: string
}

// export interface IBrandAccountReportingState {
//     //here we pass the State Interface
//     isGapiLibraryLoading: boolean,
//     isAuthorized: boolean,
//     isAuthorizing: boolean,
//     isRequestLoading: boolean,
//     // emptyResponse: boolean
//     brandAccountInfo?: any
//
// //test
//     processing: boolean
//     success?: any
//     error?: any
//     resultModalVisible: boolean
//     formRef: React.RefObject<FormInstance>
//     loadingChannelsFromTags?: boolean
//     processingInfoMultipleChannels?: { [k: string]: { processing: boolean, successData: any, error: any } }
//     resultBulkModalVisible: boolean
// }

const BrandAccountReporting: React.FC<IBrandAccountReportingProps> = (props) => {
    const [isAuthorized, setIsAuthorized] = React.useState(false);
    const [isAuthorizing, setIsAuthorizing] = React.useState(false);
    const [isRequestLoading, setIsRequestLoading] = React.useState(false);
    const [isGapiLibraryLoading, setIsGapiLibraryLoading] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [resultModalVisible, setResultModalVisible] = React.useState(false);
    const formRef = React.useRef<FormInstance>(null);
    const [resultBulkModalVisible, setResultBulkModalVisible] = React.useState(false);
    const [brandAccountInfo, setBrandAccountInfo] = useState(null)
    const [success, setSuccess] = useState<any | undefined>(undefined);
    const [error, setError] = useState<any | undefined>(undefined);
    const [reportsParams, setReportsParams] = useAtom(managedReportsParamsAtom)

    const [form] = Form.useForm<any>();
    useEffect(() => {
        // console.log({reportsParams})
        form.setFieldsValue(reportsParams)
    }, []);
    const formValues = Form.useWatch([], form)


    useEffect(() => {
        if (!formValues) return
        // console.log('useEf')
        const periodDateRangeValue = formValues.report_type == ReportTypeStaticChannel || formValues.report_type == ReportTypeChannel
            ? formValues.channel_params?.[0]?.custom_period_daterange
            : formValues.custom_period_daterange;

        if (!Array.isArray(periodDateRangeValue) || !periodDateRangeValue[0] || !periodDateRangeValue[1]) {
            if (formValues.report_type == ReportTypeStaticChannel || formValues.report_type == ReportTypeChannel) {
                // console.log('1')
                form.setFieldValue(['channel_params', 0, 'custom_period_pop'], undefined)
                form.setFieldValue(['channel_params', 0, 'custom_period_yoy'], undefined)
            } else {
                form.setFieldValue('custom_period_pop', undefined)
                form.setFieldValue('custom_period_yoy', undefined)
            }
            return
        }
        // console.log('2')
        const numDaysInPeriod = dayjs(periodDateRangeValue[1]).diff(dayjs(periodDateRangeValue[0]), 'days') + 1
        const popStart = dayjs(periodDateRangeValue[0]).subtract(numDaysInPeriod, 'days')
        const popEnd = dayjs(periodDateRangeValue[1]).subtract(numDaysInPeriod, 'days')
        // console.log('2.1', formValues)
        if (formValues.report_type == ReportTypeStaticChannel || formValues.report_type == ReportTypeChannel) {
            // console.log('3')
            if (!formValues.channel_params[0].custom_period_pop)
                form.setFieldValue(['channel_params', 0, 'custom_period_pop'], [popStart, popEnd])
        } else {
            if (!formValues.custom_period_pop)
                form.setFieldValue('custom_period_pop', [popStart, popEnd])
        }
        const yoyStart = dayjs(periodDateRangeValue[0]).subtract(1, 'year')
        const yoyEnd = dayjs(periodDateRangeValue[1]).subtract(1, 'year')
        if (formValues.report_type == ReportTypeStaticChannel || formValues.report_type == ReportTypeChannel) {
            // console.log('4')
            if (!formValues.channel_params[0].custom_period_yoy)
                form.setFieldValue(['channel_params', 0, 'custom_period_yoy'], [yoyStart, yoyEnd])
        } else {
            if (!formValues.custom_period_yoy)
                form.setFieldValue('custom_period_yoy', [yoyStart, yoyEnd])
        }
    }, [formValues]);

    const initGAPIAndSignIn = () => {
        setIsGapiLibraryLoading(true);
        return new Promise((resolve, reject) => {
            console.log('1 init gapi');
            (window as any).gapi.load('client:auth2', () => initClient(async (success: boolean) => {
                console.log('4 callback');
                if (!success) {
                    reject('Could not load gapi client');
                }

                console.log(success ? 'Loaded gapi client' : 'Could not load gapi client');
                setIsGapiLibraryLoading(false);

                console.log('Authorizing...');
                setIsAuthorizing(true);
                try {
                    const res = await authorize();
                    console.log('auth res', res);
                    setIsAuthorized(true)
                    setIsAuthorizing(false)
                    setBrandAccountInfo(res)
                    resolve(res)
                } catch (err) {
                    console.log('auth err', err);
                    setIsAuthorized(true)
                    setIsAuthorizing(false)
                    reject(err)
                }
            }));
        });
    };

    // authorize() {
    //     console.log('Authorizing...');
    //     console.log('5 authorize')
    //     this.setState({isAuthorizing: true},
    //         () => authorize()
    //             .then((res) => {
    //                 console.log('auth res', res)
    //                 this.setState({isAuthorized: true, isAuthorizing: false, brandAccountInfo: res})
    //             })
    //             .catch((err) => {
    //                 console.log('auth err', err)
    //                 this.setState({isAuthorized: false, isAuthorizing: false})
    //             })
    //     )
    // }


    const onSubmit = async (values: any) => {
        try {
            const res = await initGAPIAndSignIn()
            console.log({res})
        } catch (e) {
            console.log({e})
            return
        }
        console.log({values})

        if (values.report_type == ReportTypeChannel || values.report_type == ReportTypeStaticChannel) {
            Object.defineProperty(values, 'params',
                Object.getOwnPropertyDescriptor(values, 'channel_params')!);
            delete values['channel_params'];
            // console.log(values)

            const v = values.params[0].period
            if (values.params[0].range == Month) {
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY-MM").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY-MM").add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                    period_over_period: {
                        start_date: moment(v, "YYYY-MM").subtract(1, 'M').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-MM").subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY-MM").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-MM").subtract(1, 'y').add(1, 'M').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }


            if (values.params[0].range == Quarter) {
                console.log(values)
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY-Q").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY-Q").add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    period_over_period: {
                        start_date: moment(v, "YYYY-Q").subtract(1, 'Q').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-Q").subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY-Q").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY-Q").subtract(1, 'y').add(1, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            if (values.params[0].range == HalfYear) {
                const halfno = values.params[0].halfno
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY").add(halfno == 1 ? 2 : 4, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    //new
                    period_over_period: {
                        start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(2, 'Q').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").add(halfno == 1 ? 0 : 2, 'Q').subtract(1, 'y').add(2, 'Q').subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            if (values.params[0].range == Year) {
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(v, "YYYY").format("YYYY-MM-DD"),
                    end_date: moment(v, "YYYY").add(1, 'y').subtract(1, 'd').format("YYYY-MM-DD"),
                    //new
                    year_over_year: {
                        start_date: moment(v, "YYYY").subtract(1, 'y').format("YYYY-MM-DD"),
                        end_date: moment(v, "YYYY").subtract(1, 'd').format("YYYY-MM-DD"),
                    }
                }
            }

            if (values.params[0].range == CustomRange) {
                values.params[0] = {
                    ...values.params[0],
                    start_date: moment(values.params[0].custom_period_daterange[0]).format("YYYY-MM-DD"),
                    end_date: moment(values.params[0].custom_period_daterange[1]).format("YYYY-MM-DD"),
                    period_over_period: {
                        start_date: moment(values.params[0].custom_period_pop[0]).format("YYYY-MM-DD"),
                        end_date: moment(values.params[0].custom_period_pop[1]).format("YYYY-MM-DD"),
                    },
                    year_over_year: {
                        start_date: moment(values.params[0].custom_period_yoy[0]).format("YYYY-MM-DD"),
                        end_date: moment(values.params[0].custom_period_yoy[1]).format("YYYY-MM-DD"),
                    },
                    custom_period_pop_enabled: values.params[0].custom_period_pop_enabled,
                    custom_period_yoy_enabled: values.params[0].custom_period_yoy_enabled,
                }
            }

            // delete values.params[0]['range']
            delete values.params[0]['period']

            if (!values.report_template_requests) {
                values.report_template_requests = []
            }

            values.params[0]['is_brand_account'] = true
            // values.params[0]['auth_token'] = this.state.brandAccountInfo["Cc"]["access_token"]
            // values.params[0]['auth_token'] = this.state.brandAccountInfo["Cc"]["login_hint"]
            console.log({values})
            //find the object key which holds the token object
            let objKey = ''
            for (const key in brandAccountInfo) {
                if (brandAccountInfo[key].hasOwnProperty('access_token')) {
                    objKey = key
                    break
                }
            }
            values.params[0]['auth_token'] = brandAccountInfo[objKey]
            // console.log(values)

        }


        console.log({values})
        // console.log(values.params[0])
        // console.log(values.report_template_requests[0])
        // console.log(values.report_template_requests[1])

        // return

        setProcessing(true);
        setSuccess(null);
        setError(null);

        postRequest(`/api/youtubereports/make/${values.report_type}`, values, false).then((res) => {
            setProcessing(false);
            setSuccess(res.data);
            console.log({res});
            //    show result Modal
            setResultModalVisible(true);
        }).catch((err) => {
            let message = capitalize(err.response.data.message);
            setProcessing(false);
            setError(message);
            notification.error({message, duration: 0});
        });
    }

    return (
        <div className="BrandAccountReporting">
            <PageContainer>
                <Row style={{width: '100%'}}>
                    {/*{!this.state.isAuthorized && !this.state.isAuthorizing && !this.state.isGapiLibraryLoading &&*/}
                    {/*<Row justify='center'><Text type='danger'>You are not authorized to access the performance data*/}
                    {/*    of this asset.</Text></Row>}*/}
                    {isGapiLibraryLoading && !isRequestLoading && <Row justify='center'>
                        <Spin spinning={true}
                            // tip='Library loading...'
                        />
                    </Row>}
                    {!isGapiLibraryLoading && isAuthorizing &&
                        <Row justify='center'><Spin spinning={true} tip='Authorizing...'></Spin></Row>}

                    <div style={{width: '100%'}}>
                        <Spin spinning={isRequestLoading} tip='Loading...' style={{width: '100%'}}>
                            {/*{!this.state.isGapiLibraryLoading && this.state.isAuthorized && !this.state.isRequestLoading &&*/}

                            <>
                                <Spin spinning={processing} tip={"Processing... This won't take long."}>

                                    <Alert
                                        message={<>
                                            <Typography.Text type={"secondary"}>
                                                {"For detailed documentation, see "}
                                                <Typography.Link
                                                    href="https://developers.google.com/youtube/analytics/content_owner_reports#video-reports"
                                                    target={"_blank"}>the official docs</Typography.Link>.
                                            </Typography.Text>
                                        </>}
                                        type={"info"}
                                        showIcon={true}
                                    />

                                    <ConfigProvider locale={enUSIntl}>
                                        <ProForm size={"large"} onFinish={(values) => onSubmit(values)}
                                                 form={form} style={{width: '100%'}}
                                                 onValuesChange={(changedValues, values) => {
                                                     console.log({values})
                                                     setReportsParams(values)
                                                 }}
                                            // submitter={{submitButtonProps:{title:'test'}}}
                                                 submitter={{searchConfig: {submitText: 'Select Channel & Submit'}}}
                                        >
                                            {/*Report Type*/}
                                            <ProFormSelect name="report_type" label={"Report Type"} disabled
                                                           initialValue={ReportTypeStaticChannel}
                                                           rules={[{required: true}]}
                                                           fieldProps={{defaultActiveFirstOption: true}} // works ? maybe not
                                                           request={async () => {
                                                               let res = await postRequest('/api/youtubereports/supported_report_types')
                                                               let data = res.data
                                                               // let options: { [k: string]: string }[] = [] Doesnt work
                                                               // data.map((i: string) => options.push({i}))
                                                               let options: { label?: string, value?: string }[] = []
                                                               data.map((i: string) => options.push({
                                                                   label: camelCaseSplit(i),
                                                                   value: i
                                                               }))
                                                               // console.log({options})
                                                               return options
                                                           }}/>
                                            {/*Params*/}
                                            <ProFormDependency name={['report_type']}>
                                                {({report_type}) => {
                                                    // console.log({report_type})
                                                    if (!report_type) {
                                                        return <Empty description={"Select a report type"}/>;
                                                    }

                                                    if (report_type == ReportTypeStaticChannel) {
                                                        return (
                                                            <>
                                                                <ProFormList
                                                                    name={['channel_params']}
                                                                    label="Report Parameters"
                                                                    creatorButtonProps={false}
                                                                    deleteIconProps={false}
                                                                    copyIconProps={false}
                                                                    initialValue={[
                                                                        {}
                                                                    ]}
                                                                    key={'channel-list'}
                                                                >

                                                                    <ProFormGroup>

                                                                        <ProFormRadio.Group
                                                                            name={"range"}
                                                                            label="Range"
                                                                            radioType="button"
                                                                            options={[
                                                                                {label: 'Month', value: Month,},
                                                                                {label: 'Quarter', value: Quarter,},
                                                                                {
                                                                                    label: 'Half-Year',
                                                                                    value: HalfYear,
                                                                                },
                                                                                {label: 'Year', value: Year,},
                                                                                {label: 'Custom', value: CustomRange,},
                                                                            ]}
                                                                            initialValue={Month}
                                                                            rules={[{required: true}]}
                                                                        />


                                                                        <ProFormDependency name={['range']}>
                                                                            {({range}) => {
                                                                                // let range=range.range
                                                                                // console.log({range})
                                                                                if (!range) {
                                                                                    return <Empty
                                                                                        description={"Select range"}/>;
                                                                                }
                                                                                if (range == Month) {
                                                                                    return (
                                                                                        <>
                                                                                            <ProFormDatePicker.Month
                                                                                                name="period"
                                                                                                label="Period"
                                                                                                fieldProps={{
                                                                                                    format: 'YYYY-MM',
                                                                                                    // onChange:(v,d)=>{
                                                                                                    //     console.log(v,d)
                                                                                                    // }
                                                                                                }}

                                                                                                rules={[{
                                                                                                    // type: 'object',
                                                                                                    required: true,
                                                                                                }]}
                                                                                                initialValue={moment(moment().startOf('month').subtract(1, 'month'), "YYYY-MM-DD")}
                                                                                            />

                                                                                        </>
                                                                                    )
                                                                                }
                                                                                if (range == Quarter) {
                                                                                    return <ProFormDatePicker.Quarter
                                                                                        name="period"
                                                                                        label="Period"
                                                                                        fieldProps={{
                                                                                            format: "YYYY-Q"
                                                                                        }}

                                                                                        rules={[{
                                                                                            required: true,
                                                                                        }]}
                                                                                    />
                                                                                }
                                                                                if (range == HalfYear) {
                                                                                    return <ProFormDatePicker.Year
                                                                                        name="period"
                                                                                        label="Period"
                                                                                        addonAfter={
                                                                                            <ProFormRadio.Group
                                                                                                name="halfno"
                                                                                                noStyle
                                                                                                radioType="button"
                                                                                                options={[
                                                                                                    {
                                                                                                        label: '1st',
                                                                                                        value: 1,
                                                                                                    },
                                                                                                    {
                                                                                                        label: '2nd',
                                                                                                        value: 2,
                                                                                                    },
                                                                                                ]}
                                                                                                initialValue={1}
                                                                                                rules={[{required: true}]}
                                                                                                // transform={(_) => ({})}
                                                                                            />
                                                                                        }
                                                                                        fieldProps={{
                                                                                            format: 'YYYY'
                                                                                        }}

                                                                                        rules={[{
                                                                                            required: true,
                                                                                        }]}
                                                                                    />
                                                                                }
                                                                                if (range == Year) {
                                                                                    return <ProFormDatePicker.Year
                                                                                        name="period"
                                                                                        label="Period"
                                                                                        fieldProps={{
                                                                                            format: 'YYYY'
                                                                                        }}

                                                                                        rules={[{
                                                                                            required: true,
                                                                                        }]}
                                                                                    />
                                                                                }
                                                                                if (range == CustomRange) {
                                                                                    return <ProFormDateRangePicker
                                                                                        name="custom_period_daterange"
                                                                                        label="Period"
                                                                                        rules={[{
                                                                                            required: true,
                                                                                        }]}
                                                                                    />

                                                                                }
                                                                                return null
                                                                            }
                                                                            }
                                                                        </ProFormDependency>

                                                                        <ProFormDependency
                                                                            name={['range', 'custom_period_daterange']}>
                                                                            {({range, custom_period_daterange}) => {
                                                                                if (range == CustomRange) {
                                                                                    if (!Array.isArray(custom_period_daterange) || !custom_period_daterange[0] || !custom_period_daterange[1]) {
                                                                                        return <ProFormGroup>
                                                                                            <ProFormDateRangePicker
                                                                                                label="Compare Period 1 (PoP by default)"
                                                                                                disabled
                                                                                                addonAfter={
                                                                                                    <ProFormSwitch
                                                                                                        disabled/>}

                                                                                            />
                                                                                            <ProFormDateRangePicker
                                                                                                label="Compare Period 2 (YoY by default)"
                                                                                                disabled
                                                                                                addonAfter={
                                                                                                    <ProFormSwitch
                                                                                                        disabled/>}
                                                                                            />
                                                                                        </ProFormGroup>
                                                                                    }

                                                                                    return <ProFormGroup>
                                                                                        <ProFormDateRangePicker
                                                                                            name="custom_period_pop"
                                                                                            label="Compare Period 1 (PoP by default)"
                                                                                            fieldProps={{
                                                                                                // format: 'YYYY-MM',
                                                                                                // picker: 'month'
                                                                                                // defaultValue: [popStart, popEnd]
                                                                                            }}
                                                                                            // initialValue={[popStart, popEnd]}
                                                                                            disabled={formValues?.channels_params ? !formValues?.channels_params[0]?.custom_period_pop_enabled: undefined}
                                                                                            rules={[{
                                                                                                required: true,
                                                                                            }]}
                                                                                            addonAfter={
                                                                                                <ProFormSwitch
                                                                                                    name="custom_period_pop_enabled"
                                                                                                    checkedChildren={"Include"}
                                                                                                    unCheckedChildren={"Exclude"}
                                                                                                    initialValue={true}/>
                                                                                            }

                                                                                        />
                                                                                        <ProFormDateRangePicker
                                                                                            name="custom_period_yoy"
                                                                                            label="Compare Period 2 (YoY by default)"
                                                                                            fieldProps={{
                                                                                                // format: 'YYYY-MM',
                                                                                                // picker: 'month'
                                                                                                // defaultValue: [yoyStart, yoyEnd]
                                                                                            }}
                                                                                            // initialValue={[yoyStart, yoyEnd]}
                                                                                            rules={[{
                                                                                                required: true,
                                                                                            }]}
                                                                                            disabled={formValues?.channels_params ? !formValues?.channels_params[0]?.custom_period_yoy_enabled: undefined}
                                                                                            addonAfter={
                                                                                                <ProFormSwitch
                                                                                                    name="custom_period_yoy_enabled"
                                                                                                    checkedChildren={"Include"}
                                                                                                    unCheckedChildren={"Exclude"}
                                                                                                    initialValue={true}/>

                                                                                            }
                                                                                        />

                                                                                    </ProFormGroup>
                                                                                }
                                                                                return null
                                                                            }
                                                                            }
                                                                        </ProFormDependency>

                                                                        <ProFormDependency name={['range']}>
                                                                            {({range}) => {
                                                                                // if (range == HalfYear || range == Year) return null
                                                                                return (<ProFormRadio.Group
                                                                                    name={"verbose"}
                                                                                    label="Verbose"
                                                                                    radioType="button"
                                                                                    options={[
                                                                                        {
                                                                                            label: 'Yes',
                                                                                            value: Verbose,
                                                                                        },
                                                                                        {
                                                                                            label: 'No',
                                                                                            value: NonVerbose,
                                                                                        }
                                                                                    ]}
                                                                                    initialValue={Verbose}
                                                                                    rules={[{required: true}]}
                                                                                    // disabled={range == HalfYear || range == Year}
                                                                                    // help={range == HalfYear || range == Year ? 'Only non-comparative reports can be exported.' : 'Applies to every template request where Verbose=Auto.'}
                                                                                    help={range == Year ? 'Only non-verbose reports can be exported.' : 'Applies to every template request where Verbose=Auto.'}
                                                                                />)
                                                                            }}
                                                                        </ProFormDependency>

                                                                    </ProFormGroup>

                                                                </ProFormList>
                                                            </>

                                                        )
                                                    }


                                                    return null;
                                                }}


                                            </ProFormDependency>

                                            {/*<Row>*/}
                                            {/*    <Col span={24}>*/}
                                            <ProFormDependency name={['report_type']} ignoreFormListField
                                                               style={{width: '100%'}}>
                                                {({report_type}) => {
                                                    // console.log({report_type})
                                                    if (!report_type) {
                                                        return null;
                                                        // return <Empty description={"Select a report type"}/>;
                                                    }

                                                    if (report_type.includes("Static")) {
                                                        return <>
                                                            <Alert
                                                                message={"The templates included in the static report are the following:"}
                                                                showIcon
                                                            />
                                                            <Alert
                                                                message={"" +
                                                                    // "The templates included in the static report are the following:\n" +
                                                                    "- Key Channel Metrics\n" +
                                                                    // "\t* Key Channel Metrics\n" +
                                                                    "- Performance Overview\n" +
                                                                    "\t* Views\n" +
                                                                    "\t* Watchtime\n" +
                                                                    "\t* Top Videos\n" +
                                                                    "\t* Subscribers vs Non Subscribers Watchtime\n" +
                                                                    "\t* Subscribers Growth\n" +
                                                                    "\t* Subscribers Source\n" +
                                                                    "\t* Subscribers YouTube Watch Page\n" +
                                                                    "- Channel Reach\n" +
                                                                    "\t* Traffic Sources (Views)\n" +
                                                                    "\t* Traffic Sources: YouTube Search (Keywords)\n" +
                                                                    "\t* Devices\n" +
                                                                    "\t* Playback Locations\n" +
                                                                    "- Audience Demographics\n" +
                                                                    "\t* Age Groups\n" +
                                                                    "\t* Gender\n" +
                                                                    "\t* Geography\n" +
                                                                    "- Engagement\n" +
                                                                    "\t* Likes\n" +
                                                                    "\t* Shares\n" +
                                                                    "\t* Endscreens/Cards\n" +
                                                                    "\t* Playlists\n"
                                                                    // "Please not that these templates cannot be modified by the user in the 'static' report type."
                                                                }
                                                                type={"info"}
                                                                // showIcon
                                                                style={{whiteSpace: "pre"}}
                                                            />
                                                            <Alert
                                                                message={"Please not that these templates cannot be modified by the user in the 'static' report type."}
                                                                showIcon
                                                            />
                                                            <br/>
                                                        </>
                                                    }


                                                }}
                                            </ProFormDependency>
                                            {/*</Col>*/}
                                            {/*</Row>*/}
                                        </ProForm>
                                    </ConfigProvider>
                                </Spin>


                                {/*Result Modal For single channel*/}
                                {success &&
                                    <Modal
                                        visible={resultModalVisible}
                                        onOk={() => {
                                            setResultModalVisible(false)
                                        }}
                                        closable={false}
                                        maskClosable={true}
                                        cancelButtonProps={{hidden: true}}
                                    >
                                        <Result title={"Report Created Successfully!"} status="success" subTitle={
                                            <>
                                                <Typography.Text type={"secondary"}>Shared with:
                                                    digitalminds.com</Typography.Text>
                                            </>
                                        }
                                                extra={[
                                                    <a href={success.presentationUrl} target={"_blank"}>
                                                        <Button size={"large"} type="primary" key="presentation"
                                                                icon={<FilePptOutlined/>}>Go To
                                                            Presentation</Button>
                                                    </a>,
                                                    <a href={success.spreadsheetUrl} target={"_blank"}>
                                                        <Button size={"large"} key="spreadsheet"
                                                                icon={<FileExcelOutlined/>}>Go To
                                                            Spreadsheet</Button>
                                                    </a>
                                                ]}
                                        />

                                    </Modal>
                                }
                            </>
                            {/*}*/}

                        </Spin>
                    </div>
                </Row>

            </PageContainer>
        </div>
    );

}

export default BrandAccountReporting;