import * as React from 'react';
import {Suspense, useEffect} from 'react';
import {Button, DatePicker, Row, Segmented, Skeleton, Space, Switch, Tooltip} from "antd";
import dayjs from "dayjs";
import {PageContainer, ProFormSelect} from "@ant-design/pro-components";
import {UserAuth} from "../../../../context/AuthContext";
import {useAtom, useAtomValue, useSetAtom} from "jotai";
import {RESET} from "jotai/utils";
import {
    channelsAtom,
    compareCategoriesModalSelectedCategoriesAtom,
    filterChannelCategoriesAtom,
    hideNegativeDataAtom,
    monthAtom,
    monthlyViewsAtom,
    monthModalSelectedChannelsAtom,
    rankingViewsAtom,
    showCompareCategoriesModalAtom,
    showMonthModalAtom,
    ViewType,
    viewTypeAtom,
    yearAtom,
    yearlyViewsAtom
} from "./atoms";
import {Access} from "../../../../components/Access";
import {ExportOutlined, MinusOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import FilterCategories from "./FilterCategories";
import ExportToExcel from "./ExportToExcel";
import {LightFilter} from "@ant-design/pro-form";
import MonthView from "./MonthView";
import YearView from "./YearView";
import RankingView from "./RankingView";
import MonthlyChartModal from "./MonthlyChartModal";
import BenchmarksChannels from "./BenchmarksChannels";
import CompareCategoriesChartModal from "./CompareCategoriesChartModal";

type Props = {};

const numFormatterLocale = 'el-GR'
export const numFormatterInt = new Intl.NumberFormat(numFormatterLocale, {maximumFractionDigits: 0});
export const numFormatterDec = new Intl.NumberFormat(numFormatterLocale, {maximumFractionDigits: 2});
export const numFormatterPercent = new Intl.NumberFormat(numFormatterLocale, {
    style: 'percent',
    maximumFractionDigits: 1
});

export const YouTubeBenchmarks = (props: Props) => {
    const [viewType, setViewType] = useAtom(viewTypeAtom);
    const [year, setYear] = useAtom(yearAtom);
    const [month, setMonth] = useAtom(monthAtom);

    const refreshChannels = useSetAtom(channelsAtom)
    const refreshMonthlyViews = useSetAtom(monthlyViewsAtom)
    const refreshYearlyViews = useSetAtom(yearlyViewsAtom)
    const refreshRankingViews = useSetAtom(rankingViewsAtom)

    const showMonthModal = useAtomValue(showMonthModalAtom);
    const [showCompareCategoriesModal, setShowCompareCategoriesModal] = useAtom(showCompareCategoriesModalAtom)
    const filterChannelCategories = useAtomValue(filterChannelCategoriesAtom)
    const setSelectedCategories = useSetAtom(compareCategoriesModalSelectedCategoriesAtom);

    const {can} = UserAuth();

    useEffect(() => {
        if (viewType === 'year') {
            setMonth(RESET)
        }
        if (viewType === 'month' && !month) {
            setMonth(dayjs().subtract(1, 'month').month() + 1)
        }
        if (viewType === 'ranking') {
            setYear(RESET)
            setMonth(RESET)
        }
    }, [viewType]);


    useEffect(() => {
        if (viewType === 'year') {
            setMonth(RESET)
            refreshYearlyViews()
        }
        if (viewType === 'month') {
            refreshMonthlyViews()
        }
    }, [year, month]);

    const exportBtnDisabled = (
        <Button
            size={'large'}
            type={'text'}
            icon={<ExportOutlined/>}
            disabled
        />
    )
    const categoryFilterDisabled = (
        <LightFilter>
            <ProFormSelect disabled placeholder={"Filter by industry"}/>
        </LightFilter>
    )

    const contentLoading = (
        <Skeleton style={{marginTop: 24}} active/>
    )

    return (
        <PageContainer>
            <Row
                justify={'space-between'}
            >
                <Segmented options={
                    can('view', 'benchmarks::breakdown') ? [
                            {label: 'Month', value: 'month',},
                            {label: 'Year', value: 'year',},
                            {label: 'Ranking', value: 'ranking',},
                            {label: 'Channels', value: 'channels',}
                        ] :
                        [
                            {label: 'Year', value: 'year',},
                            {label: 'Ranking', value: 'ranking',},
                            {label: 'Channels', value: 'channels',}
                        ]}
                           value={viewType}
                           onChange={(value) => {
                               setViewType(value as ViewType);
                           }}
                           onResize={undefined} onResizeCapture={undefined}
                           size={'large'}
                />

                {viewType === 'month' && (
                    <Suspense fallback={'...'}>
                        <Access accessible={can('view', 'benchmarks::breakdown')} fallback={null}>
                            <Space>

                                <Suspense fallback={<Switch disabled style={{marginRight: 8}}/>}>
                                    <HideNegativeDataToggle/>
                                </Suspense>

                                <Suspense fallback={<Button
                                    type="dashed"
                                    size={'large'}
                                    icon={<PlusOutlined/>}
                                    style={{marginRight: 8}}
                                    disabled
                                >
                                    Compare Channels
                                </Button>}>
                                    <CompareChannelsButton/>
                                </Suspense>
                                <DatePicker.MonthPicker
                                    value={dayjs(`${year}-${month}-01`)}
                                    onChange={value => {
                                        setYear(value.year());
                                        setMonth(value.month() + 1);
                                    }}
                                    size={'large'}
                                    style={{marginRight: 8}}
                                />
                                <Suspense fallback={categoryFilterDisabled}>
                                    <FilterCategories/>
                                </Suspense>
                                <Tooltip title={'Refresh'}>
                                    <Button
                                        size={'large'}
                                        type={'text'}
                                        icon={<ReloadOutlined/>}
                                        onClick={() => {
                                            refreshMonthlyViews()
                                        }}
                                    />
                                </Tooltip>
                                <Suspense fallback={exportBtnDisabled}>
                                    <ExportToExcel/>
                                </Suspense>
                            </Space>
                        </Access>
                    </Suspense>
                )}

                {viewType === 'year' && (
                    <Suspense fallback={'...'}>
                        <Space>

                            <Suspense fallback={<Switch disabled style={{marginRight: 8}}/>}>
                                <HideNegativeDataToggle/>
                            </Suspense>

                            <DatePicker
                                picker={'year'}
                                value={dayjs(`${year}-01-01`)}
                                onChange={value => {
                                    setYear(value.year());
                                }}
                                size={'large'}
                                style={{marginRight: 8}}
                            />
                            <Suspense fallback={categoryFilterDisabled}>
                                <FilterCategories/>
                            </Suspense>
                            <Tooltip title={'Refresh'}>
                                <Button
                                    size={'large'}
                                    type={'text'}
                                    icon={<ReloadOutlined/>}
                                    onClick={() => {
                                        refreshYearlyViews()
                                    }}
                                />
                            </Tooltip>
                            <Suspense fallback={exportBtnDisabled}>
                                <ExportToExcel/>
                            </Suspense>
                        </Space>
                    </Suspense>
                )}

                {viewType === 'ranking' && (
                    <Suspense fallback={'...'}>
                        <Space>
                            <Suspense fallback={categoryFilterDisabled}>
                                <FilterCategories/>
                            </Suspense>
                            <Tooltip title={'Refresh'}>
                                <Button
                                    size={'large'}
                                    type={'text'}
                                    icon={<ReloadOutlined/>}
                                    onClick={() => {
                                        refreshRankingViews()
                                    }}
                                />
                            </Tooltip>
                            <Suspense fallback={exportBtnDisabled}>
                                <ExportToExcel/>
                            </Suspense>
                        </Space>
                    </Suspense>
                )}

                {viewType === 'channels' && (
                    <Suspense fallback={'...'}>
                        <Space>
                            <Button
                                type="dashed"
                                size={'large'}
                                icon={<PlusOutlined/>}
                                onClick={() => {
                                    setShowCompareCategoriesModal(true)
                                    if (filterChannelCategories.length > 0) {
                                        setSelectedCategories(filterChannelCategories)
                                    }
                                }}
                                style={{marginRight: 8}}
                            >
                                Compare Industries
                            </Button>
                            <Suspense fallback={categoryFilterDisabled}>
                                <FilterCategories/>
                            </Suspense>
                            <Tooltip title={'Refresh'}>
                                <Button
                                    size={'large'}
                                    type={'text'}
                                    icon={<ReloadOutlined/>}
                                    onClick={() => {
                                        refreshChannels()
                                    }}
                                />
                            </Tooltip>
                            <Suspense fallback={exportBtnDisabled}>
                                <ExportToExcel/>
                            </Suspense>
                        </Space>
                    </Suspense>
                )}


            </Row>

            <br/>
            <Row>
                {viewType === 'month' && (
                    <Suspense fallback={contentLoading}>
                        <MonthView/>
                    </Suspense>
                )}

                {viewType === 'year' && (
                    <Suspense fallback={contentLoading}>
                        <YearView/>
                    </Suspense>
                )}


                {viewType === 'ranking' && (
                    <Suspense fallback={contentLoading}>
                        <RankingView/>
                    </Suspense>
                )}

            </Row>

            {viewType === 'month' && showMonthModal && (
                <MonthlyChartModal/>
            )}

            {viewType === 'channels' && (
                <Suspense fallback={contentLoading}>
                    <BenchmarksChannels/>
                </Suspense>
            )}
            {viewType === 'channels' && showCompareCategoriesModal && (
                <CompareCategoriesChartModal/>
            )}


        </PageContainer>

    );

};


export const CompareChannelsButton = (props: Props) => {
    const channels = useAtomValue(channelsAtom)
    const setSelectedChannels = useSetAtom(monthModalSelectedChannelsAtom);
    const setShowMonthModal = useSetAtom(showMonthModalAtom);

    return (
        <Button
            type="dashed"
            size={'large'}
            icon={<PlusOutlined/>}
            onClick={() => {
                setShowMonthModal(true)
                if (channels.length > 0 && channels.length < 5) {
                    setSelectedChannels(channels.map(channel => channel.id))
                }
            }}
            style={{marginRight: 8}}
        >
            Compare Channels
        </Button>
    );
};

export const HideNegativeDataToggle = (props: Props) => {
    const [hideNegativeData, setHideNegativeData] = useAtom(hideNegativeDataAtom);
    return (
        <Tooltip
            title={'When enabled, negative values for views, likes, dislikes, comments and shares will be treated as zero.'}>
            <Switch
                checked={hideNegativeData}
                onChange={(checked) => { setHideNegativeData(checked) }}
                title={'Hide negative data'}
                unCheckedChildren={<MinusOutlined/>}
                checkedChildren={<PlusOutlined/>}
                style={{marginRight: 8}}
            />
        </Tooltip>
    );
}

export default YouTubeBenchmarks;
