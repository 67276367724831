import * as React from 'react';
import { Avatar, Row, Tag, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { numFormatterInt } from './YouTubeBenchmarks';
import { useAtom, useAtomValue } from 'jotai';
import { channelsAtom, rankingViewsAtom } from './atoms';

type Props = {};

export const RankingView = (props: Props) => {
    const [rankingViews, refreshRankingViews] = useAtom(rankingViewsAtom);
    const channels = useAtomValue(channelsAtom);
    return (
        <div style={{ width: '100%' }}>
            <Row style={{ padding: 4, marginBottom: 8 }}>
                <Typography.Text type={'secondary'}>
                    <InfoCircleOutlined /> Based on the most recent data available.
                </Typography.Text>
            </Row>
            <ProTable<API.Benchmarks.YouTubeRankingView>
                // actionRef={actionRefRanking}
                dataSource={rankingViews}
                rowKey={'channelID'}
                search={false}
                toolBarRender={false}
                // style={{width: '100%'}}
                columns={[
                    {
                        title: 'Rank',
                        dataIndex: 'rank',
                        key: 'rank',
                        valueType: 'indexBorder',
                        // render: (value, record, index) => index + 1,
                        width: 120,
                        align: 'center'
                    },
                    {
                        render: (dom, entity) => (
                            <Avatar
                                src={`data:image/jpeg;base64,${entity?.image}`}
                                style={{ width: 34, height: 34 }}
                                shape={'circle'}
                            />
                        ),
                        width: 70
                    },
                    {
                        title: 'Channel Name',
                        dataIndex: 'channelName',
                        key: 'channelName',
                        // sorter: (a, b) => a.channelName.localeCompare(b.channelName),
                        // render the name with an icon to open the channel in a new tab
                        width: 400,
                        render: (value, record) => (
                            <span>
                                {/*    <Typography.Text>{value}</Typography.Text>*/}
                                {/*{' '}*/}
                                {/*<a href={`https://www.youtube.com/channel/${record.channelID}`}*/}
                                {/*   target={'_blank'}*/}
                                {/*   rel={'noreferrer'}*/}
                                {/*>*/}
                                {/*        <ExportOutlined/>*/}
                                {/*    </a>*/}
                                <Typography.Link
                                    href={`https://www.youtube.com/channel/${record.channelID}`}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    strong
                                >
                                    {value}
                                </Typography.Link>
                            </span>
                        )
                    },
                    {
                        title: 'Total Subscribers Lifetime',
                        dataIndex: 'totalSubscribersLifetime',
                        key: 'totalSubscribersLifetime',
                        render: (value) => numFormatterInt.format(+value)
                        // sorter: (a, b) => a.totalSubscribersLifetime - b.totalSubscribersLifetime,
                        // width: 200,
                    },
                    {
                        title: 'Industries',
                        dataIndex: 'categories',
                        key: 'categories',
                        render: (dom, entity) => {
                            const categories = channels.find((c) => c.id === entity.channelID)?.categories;
                            return (
                                <>
                                    {categories.map((c) => (
                                        <Tag key={`${entity.channelID}_${c.id}`}>{c.category}</Tag>
                                    ))}
                                </>
                            );
                        }
                    }
                ]}
                // request={async () => {
                //     const response = await axiosApiInstance.get(`/api/benchmarks/ranking`);
                //     setRankingViews(response.data);
                //     return {
                //         data: response.data || [],
                //         success: true,
                //         total: response.data?.length,
                //     };
                // }}
                pagination={false}
                cardBordered
            />
        </div>
    );
};

export default RankingView;
