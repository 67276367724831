import { atomWithHash } from 'jotai-location';
import dayjs from 'dayjs';
import { atom } from 'jotai';
import { loadable } from 'jotai/utils';
import axiosApiInstance from '../../../../api/axiosClient';
import { atomWithRefresh } from '../../../../common/atom_utils';
import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';

export type ViewType = 'month' | 'year' | 'ranking' | 'channels';
export const viewTypeAtom = atomWithHash<ViewType>('viewType', 'ranking');
export const yearAtom = atomWithHash<number>('year', dayjs().subtract(1, 'month').year());
export const monthAtom = atomWithHash<number>('month', dayjs().subtract(1, 'month').month() + 1);
export const showMonthModalAtom = atomWithHash('showMonthModal', false);
export const showCompareCategoriesModalAtom = atomWithHash('showCompareCategoriesModal', false);
export const monthModalSelectedChannelsAtom = atomWithHash<string[]>('monthModalSelectedChannels', []);
export const compareCategoriesModalSelectedCategoriesAtom = atomWithHash<number[]>(
    'compareCategoriesModalSelectedCategories',
    []
);
export const selectedChannelMetricAtom = atomWithHash<string>(
    'selectedChannelMetric',
    'Total Subscribers (Lifetime)'
);
export const selectedChannelMetricObjAtom = atom<any>(async (get) => {
    const channelMetrics = await get(channelMetricsAtom);
    return channelMetrics.find((metric) => metric.data_index === get(selectedChannelMetricAtom));
});

export const yFieldAtom = atom(async (get) => (await get(selectedChannelMetricObjAtom))?.json_index);

export const rankedChannelsAtom = atom<Promise<API.Benchmarks.TiktokBenchmarksAccountDataPoint[]>>(
    async (get) => {
        const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/ranking`);
        // console.log(response.data)
        return response.data;
    }
);

export const channelMetricsAtom = atom(async (get) => {
    const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/metrics`);
    // console.log(response.data)
    return response.data;
});

export const monthlyChartDataAtom = atom(async (get) => {
    // const sc = get(monthModalSelectedChannelsAtom);
    // const sm = get(selectedChannelMetricAtom);
    // const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/monthly_channel_data`,
    //     {params: {selectedChannelIDs: sc, selectedMetric: sm}});
    // // console.log(response.data)
    // return response.data?.sort  ((a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix()) || [];
    return [];
});

export const loadableMonthlyChartDataAtom = loadable(monthlyChartDataAtom);

// export const channelsAtom = atomWithRefresh<Promise<API.Benchmarks.TiktokBenchmarksAccount[]>>(async (get) => {
//     const categoryIDs = get(filterChannelCategoriesAtom);
//     const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/accounts`, {params: {filter_category_ids: categoryIDs}});
//     // console.log(response.data)
//     return response.data;
// });

export const channelsAtom = atomWithSuspenseQuery<API.Benchmarks.TiktokBenchmarksAccount[]>((get) => ({
    queryKey: [
        '/benchmarks/tiktok/accounts',
        { account_type: get(filterAccountTypeAtom), filter_category_ids: get(filterChannelCategoriesAtom) }
    ],
    queryFn: async ({ queryKey }) => {
        // console.log('ranking', queryKey)
        const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/accounts`, {
            params: {
                account_type: get(filterAccountTypeAtom),
                filter_category_ids: get(filterChannelCategoriesAtom)
            }
        });
        return response.data;
    }
}));

// export const channelCategoriesAtom = atomWithRefresh<Promise<API.Benchmarks.YouTubeBenchmarksChannelCategory[]>>(async (get) => {
//     // const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/channel_categories`);
//     // // console.log(response.data)
//     // return response.data;
//     return [];
// });

export const channelCategoriesAtom = atomWithRefresh<Promise<API.Benchmarks.TikTokBenchmarksIndustry[]>>(
    async (get) => {
        const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/categories`);
        // console.log(response.data)
        return response.data;
    }
);

export const filterChannelCategoriesAtom = atomWithHash<number[]>('filterChannelCategories', []);

// account type can be BRAND or CREATOR
export const filterAccountTypeAtom = atomWithHash<string>('filterAccountType', 'BRAND');

export const filterSearchAtom = atomWithHash<string | undefined>('filterSearch', undefined);

export const searchFilteredChannelsAtom = atom(async (get) => {
    const search = get(filterSearchAtom);
    const { data: channels } = await get(channelsAtom);
    if (!search) {
        return channels;
    }
    return channels.filter((channel: any) => channel.name.toLowerCase().includes(search.toLowerCase()));
});

// export const monthlyViewsAtom = atomWithRefresh<Promise<{
//     data: API.Benchmarks.TiktokBenchmarksAccountDataPoint[];
//     avg: API.Benchmarks.TiktokBenchmarksAccountDataPoint
// }>>(async (get) => {
//     const categoryIDs = get(filterChannelCategoriesAtom);
//     const year = get(yearAtom);
//     const month = get(monthAtom);
//     const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/monthly`, {
//         params: {
//             year,
//             month,
//             filter_category_ids: categoryIDs
//         }
//     });
//     // console.log(response.data)
//     return response.data;
// });

export const monthlyViewsAtom = atomWithSuspenseQuery<{
    data: API.Benchmarks.TiktokBenchmarksAccountDataPoint[];
    avg: API.Benchmarks.TiktokBenchmarksAccountDataPoint;
}>((get) => ({
    queryKey: [
        '/benchmarks/tiktok/monthly',
        { account_type: get(filterAccountTypeAtom), filter_category_ids: get(filterChannelCategoriesAtom) }
    ],
    queryFn: async ({ queryKey }) => {
        // console.log('ranking', queryKey)
        const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/monthly`, {
            params: {
                account_type: get(filterAccountTypeAtom),
                year: get(yearAtom),
                month: get(monthAtom),
                filter_category_ids: get(filterChannelCategoriesAtom)
            }
        });
        console.log({ data: response.data });
        return response.data;
    }
}));

// export const yearlyViewsAtom = atomWithRefresh<Promise<{
//     data: API.Benchmarks.TiktokBenchmarksAccountDataPoint[];
//     avg: API.Benchmarks.TiktokBenchmarksAccountDataPoint
// }>>(async (get) => {
//     const categoryIDs = get(filterChannelCategoriesAtom);
//     const year = get(yearAtom);
//     const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/yearly`, {
//         params: {
//             year,
//             filter_category_ids: categoryIDs
//         }
//     });
//     // console.log(response.data)
//     return response.data;
// });

export const yearlyViewsAtom = atomWithSuspenseQuery<{
    data: API.Benchmarks.TiktokBenchmarksAccountDataPoint[];
    avg: API.Benchmarks.TiktokBenchmarksAccountDataPoint;
}>((get) => ({
    queryKey: [
        '/benchmarks/tiktok/yearly',
        { account_type: get(filterAccountTypeAtom), filter_category_ids: get(filterChannelCategoriesAtom) }
    ],
    queryFn: async ({ queryKey }) => {
        // console.log('ranking', queryKey)
        const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/yearly`, {
            params: {
                account_type: get(filterAccountTypeAtom),
                year: get(yearAtom),
                filter_category_ids: get(filterChannelCategoriesAtom)
            }
        });
        return response.data;
    }
}));

// export const rankingViewsAtom = atomWithRefresh<Promise<API.Benchmarks.TiktokBenchmarksAccountDataPoint[]>>(async (get) => {
//     const categoryIDs = get(filterChannelCategoriesAtom);
//     const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/ranking`, {
//         params: {
//             filter_category_ids: categoryIDs
//         }
//     });
//     return response.data;
// });

export const rankingViewsAtom = atomWithQuery<API.Benchmarks.TiktokBenchmarksAccountDataPoint[]>((get) => ({
    queryKey: [
        '/benchmarks/tiktok/ranking',
        { account_type: get(filterAccountTypeAtom), filter_category_ids: get(filterChannelCategoriesAtom) }
    ],
    queryFn: async ({ queryKey }) => {
        // console.log('ranking', queryKey)
        const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/ranking`, {
            params: {
                account_type: get(filterAccountTypeAtom),
                filter_category_ids: get(filterChannelCategoriesAtom)
            }
        });
        return response.data;
    }
}));

export const monthModalFilterSearchAtom = atomWithHash<string | undefined>(
    'monthModalFilterSearch',
    undefined
);

export const monthModalSearchFilteredChannelsAtom = atom(async (get) => {
    const rankedChannels = await get(rankedChannelsAtom);
    const monthModalSelectedChannels = get(monthModalSelectedChannelsAtom);
    const search = get(monthModalFilterSearchAtom);
    // console.log(search, monthModalSelectedChannels, rankedChannels)
    if (!search) {
        return rankedChannels;
    }
    const filteredAccounts = [
        // first the selected
        ...monthModalSelectedChannels?.map((userID: string) =>
            rankedChannels.find((account) => account.userID === userID)
        ),
        // then the searched, but only if not already selected
        ...rankedChannels.filter(
            (channel) =>
                channel.userID?.toLowerCase().includes(search?.toLowerCase()) &&
                !monthModalSelectedChannels.includes(channel.userID)
        )
    ];
    return filteredAccounts;
});

export const compareCategoriesChartDataAtom = atom(async (get) => {
    // const sc = get(compareCategoriesModalSelectedCategoriesAtom);
    // const sm = get(selectedChannelMetricAtom);
    // const response = await axiosApiInstance.get(`/api/benchmarks/tiktok/monthly_data_by_category`,
    //     {params: {selectedCategoryIDs: sc, selectedMetric: sm}});
    // console.log(response.data)
    // return response.data?.sort((a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix());
    return [];
});

export const loadableCompareCategoriesChartDataAtom = loadable(compareCategoriesChartDataAtom);
